<template>
  <div class="animal">
    <div class="image">
      <img
        :src="photo"
        width="100%"
        class="avatar-md rounded-circle"
      >
    </div>
    <div class="info">
      <h6>{{ animal.name }}</h6>
      <small>{{ animal.codeIso }}</small>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
const animalPlaceholder = require('../../assets/img/animalPlaceholder.png')
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux'
        }),
        animal(){
                return this.animaux.find(item => item.uid === this.object.animal)
        },
        hasAnimal () {return this.animal !== null && this.animal !== undefined},
        photo(){
            if(this.hasAnimal) return this.animal.photo !== null ? JSON.parse(this.animal.photo).url : animalPlaceholder
            return animalPlaceholder 
        },
        
    }
}
</script>

<style lang="scss" scoped>
.animal{
    width: 180px;
    position: relative;
    .image{
        width: 50%;
    }
    .info{
        position: absolute;
        right: 5px;
        top: 0px;
    }
}
</style>